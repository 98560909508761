<template>
<div>
  <UserTop :title="title" @userInfo="getUserInfo">
    <div class="right-box">

      <div class="user-item" v-if="userInfoItem">
        <div class="user-name">
          <el-button type="success" size="small" @click="goNav('/index/promot')">加入推广</el-button>
        </div>
        <div class="user-info">
          <div>
            <span>累计订单数：</span>
            <span>{{ userInfoItem.totalOrderCount }} 单</span>
          </div>
          <div>
            <span>累计收益：</span>
            <span class="col">{{ userInfoItem.totalMoney }} 元</span>
          </div>
          <div>
            <span>当前余额：</span>
            <span class="col">{{ userInfoItem.balance }} 元</span>
          </div>
          <div class="btn" @click="openWithdraw">
            <i class="el-icon-money"></i>
            <span>提 现</span>
          </div>
        </div>
      </div>
      <div class="tabs-box">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="我的推广" name="shareOrder">
            <div class="tabs-item" v-loading="loading">
              <el-table :data="selectlist" stripe v-if="selectlist && selectlist.length > 0">
                <el-table-column label="推广订单ID" align="left" width="200" prop="paperOrderId"></el-table-column>
                <el-table-column label="推广订单标题" align="left" width="220" prop="paperOrderTitle" :show-overflow-tooltip="true"></el-table-column>
                <el-table-column label="分成比例" align="left" width="120">
                  <template slot-scope="scope">
                    {{(Number(scope.row.percentage) * 100)}}%
                  </template>
                </el-table-column>
                <el-table-column label="分成金额" align="left" width="120" prop="percentagePrice"></el-table-column>
                <el-table-column label="时间" align="left" width="160" prop="createTime"></el-table-column>
                <el-table-column label="信息" align="left" prop="description" :show-overflow-tooltip="true"></el-table-column>
                <el-table-column label="发放状态" align="left" width="120">
                  <template slot-scope="scope">
                    <el-tag v-if="scope.row.state === 0" type="warning">待发放</el-tag>
                    <el-tag v-else-if="scope.row.state === 1" type="success">已发放</el-tag>
                    <el-tag v-else-if="scope.row.state === 2" type="danger">终止发放</el-tag>
                  </template>
                </el-table-column>
              </el-table>
              <el-empty v-else :image-size="100" description="暂无数据"></el-empty>
            </div>
          </el-tab-pane>
          <el-tab-pane label="充值记录" name="payPage">
            <div class="tabs-item" v-loading="loading">
              <el-table :data="selectlist" stripe v-if="selectlist && selectlist.length > 0">
                <el-table-column label="订单号" align="left" width="200" prop="orderId"></el-table-column>
                <el-table-column label="标题" align="left" prop="title"></el-table-column>
                <el-table-column label="充值金额" align="left" prop="price"></el-table-column>
                <el-table-column label="时间" align="left" width="160" prop="createTime"></el-table-column>
              </el-table>
              <el-empty v-else :image-size="100" description="暂无数据"></el-empty>
            </div>
          </el-tab-pane>
          <el-tab-pane label="提现申请" name="withdraw">
            <div class="tabs-item" v-loading="loading">
              <el-table :data="selectlist" stripe v-if="selectlist && selectlist.length > 0">
                <el-table-column label="账号" align="left" prop="account"></el-table-column>
                <el-table-column label="姓名" align="left" prop="name"></el-table-column>
                <el-table-column label="金额" align="left" prop="price"></el-table-column>
                <el-table-column label="申请时间" align="left" width="160" prop="createTime"></el-table-column>
                <el-table-column label="提现结果" align="left" prop="description" :show-overflow-tooltip="true"></el-table-column>
                <el-table-column label="状态" align="left">
                  <template slot-scope="scope">
                    <el-tag v-if="scope.row.state === 0" type="warning">待处理</el-tag>
                    <el-tag v-else-if="scope.row.state === 1" type="success">已发放</el-tag>
                    <el-tag v-else-if="scope.row.state === 2" type="danger">发放失败</el-tag>
                  </template>
                </el-table-column>
              </el-table>
              <el-empty v-else :image-size="100" description="暂无数据"></el-empty>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
      <div class="pagination" v-if="total && total > 0">
        <el-pagination class="pc-show" @size-change="handleSizeChange" @current-change="handleCurrentChange"
                       :current-page="queryInfo.pageNum" :page-sizes="[10, 20, 30]" :page-size="queryInfo.pageSize"
                       layout="total, sizes, prev, pager, next, jumper" :total="total">
        </el-pagination>

        <el-pagination class="h5-show" @size-change="handleSizeChange" @current-change="handleCurrentChange"
                       :current-page="queryInfo.pageNum" :page-size="queryInfo.pageSize"
                       layout="total, prev, pager, next" :total="total">
        </el-pagination>
      </div>

    </div>

    <el-dialog
      title="提现申请"
      :visible.sync="withdrawDialogVisible"
      @close="closedialogVisible"
      class="width-show"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      center>
      <div class="from-dialog-box">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="demo-ruleForm" @submit.native.prevent>
          <el-form-item label="姓名:" prop="name">
            <el-input v-model="ruleForm.name"
                      type="text"
                      placeholder="请输入提现人姓名"></el-input>
          </el-form-item>
          <el-form-item label="支付宝账号：" prop="account">
            <el-input v-model="ruleForm.account"
                      type="text"
                      placeholder="请输入提现账号"></el-input>
          </el-form-item>
          <el-form-item label="选择提现金额" prop="price">
            <el-radio-group v-model="ruleForm.price">
              <el-radio-button v-for="item in priceList" :label="item">{{item}}</el-radio-button>
            </el-radio-group>
          </el-form-item>
        </el-form>
        <div class="form-dialog-btn">
          <el-button style="width: 120px" type="primary" @click="submitForm('ruleForm')">确认提现</el-button>
        </div>
      </div>
    </el-dialog>
  </UserTop>
</div>
</template>

<script>
import PromotionApi from "@/api/promotion"
import UserTop from "@/views/user_promot/components/userTop/index.vue"
export default {
  components: {UserTop},
  data() {
    return {
      queryInfo: {
        pageNum: 1,
        pageSize: 10
      },
      selectlist: [],
      selectIndex: 0,
      total: 0,
      title: '我的推广',
      userInfo: {
        isLogin: false, // 用户登录状态
        username: '',
        nickname: '',
        token: ''
      },
      userInfoItem: '',
      activeName: 'shareOrder',
      withdrawDialogVisible: false,
      ruleForm: {
        account: '',
        name: '',
        price: 30
      },
      rules: {
        account: [
          { required: true, message: '请输入提现账号', trigger: 'blur' }
        ],
        name: [
          { required: true, message: '请输入提现人姓名', trigger: 'blur' }
        ],
        price: [
          { required: true, message: '请选择提现金额', trigger: 'change' }
        ],
      },
      priceList: ['30','50','100','200','500','1000'],
      loading: false
    }
  },
  created() {
    let data = JSON.parse(localStorage.getItem('userInfo'))
    if(data){
      this.userInfo = data
    }
    this.postShareOrder()
    this.getApiUserInfo()
  },
  methods: {
    getUserInfo(msg){
      if (msg && msg.user === false){
        this.userInfo = this.$options.data().userInfo
        localStorage.removeItem('userInfo')
      }else {
        this.userInfo = msg.info
      }
    },
    goNav(url){
      this.$router.push(url)
    },
    handleClick(tab) {
      // console.log(tab.index);
      this.loading = true
      this.selectIndex = Number(tab.index)
      this.queryInfo = this.$options.data().queryInfo
      if(this.selectIndex === 0){
        this.postShareOrder()
      }else if(this.selectIndex === 1){
        this.postPayPage()
      }else if(this.selectIndex === 2){
        this.postWithdrawPage()
      }
      this.getApiUserInfo()
    },
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize
      if(this.selectIndex === 0){
        this.postShareOrder()
      }else if(this.selectIndex === 1){
        this.postPayPage()
      }else if(this.selectIndex === 2){
        this.postWithdrawPage()
      }
    },
    handleCurrentChange(newNum) {
      this.queryInfo.pageNum = newNum
      if(this.selectIndex === 0){
        this.postShareOrder()
      }else if(this.selectIndex === 1){
        this.postPayPage()
      }else if(this.selectIndex === 2){
        this.postWithdrawPage()
      }
    },
    // 获取用户信息
    getApiUserInfo(){
      let _this = this;
      let token = this.userInfo.token
      PromotionApi.getUserInfo(token).then(res => {
        _this.userInfoItem = res.data
      }).catch(() => {
      })
    },
    // 查询推广订单
    postShareOrder() {
      let _this = this;
      let token = this.userInfo.token
      PromotionApi.postShareOrder(token,_this.queryInfo).then(res => {
        _this.selectlist = res.data.data
        _this.total = res.data.total
        _this.loading = false
      }).catch(() => {
        localStorage.removeItem('userInfo')
        _this.$router.push('promot')
      })
    },
    // 查询充值记录
    postPayPage() {
      let _this = this;
      let token = this.userInfo.token
      PromotionApi.postPayPage(token,_this.queryInfo).then(res => {
        _this.selectlist = res.data.data
        _this.total = res.data.total
        _this.loading = false
      }).catch(() => {
      })
    },
    // 查询提现申请
    postWithdrawPage() {
      let _this = this;
      let token = this.userInfo.token
      PromotionApi.postWithdrawPage(token,_this.queryInfo).then(res => {
        _this.selectlist = res.data.data
        _this.total = res.data.total
        _this.loading = false
      }).catch(() => {
      })
    },
    // 打开提现窗口
    openWithdraw(){
      this.withdrawDialogVisible = true
    },
    closedialogVisible(){
      this.withdrawDialogVisible = false
      this.ruleForm = this.$options.data().ruleForm
    },
    submitForm(formName) {
      let _this = this
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // 提交提现申请
          _this.postWithdraw()
        } else {
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    // 提交提现申请
    postWithdraw() {
      let _this = this;
      let token = _this.userInfo.token
      PromotionApi.postWithdraw(token,_this.ruleForm).then(res => {
        // console.log(res.data)
        _this.$message.success('提现申请完成')
        // 更新用户信息
        _this.getApiUserInfo()
        // 更新表格
        _this.postWithdrawPage()
        // 关闭弹窗
        _this.closedialogVisible()
      }).catch(() => {
      })
    },
  }
}
</script>

<style lang="less" scoped>
.right-box{
  width: calc(100% - 200px);
  .user-item{
    padding: 16px 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    .user-name{
      color: #8b9098;
    }
    .user-info{
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      >div{
        margin-left: 20px;
        span.col{
          color: #ee6360;
        }
      }
      .btn{
        width: 80px;
        height: 32px;
        border-radius: 5px;
        background-image: linear-gradient(90deg,#409EFF,#6e83f7);
        box-shadow: 0 2px 5px rgba(81, 59, 248, 0.3);
        color: #ffffff;
        cursor: pointer;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        i{
          margin-right: 6px;
        }
      }
    }
  }
  .tabs-box{
    border-radius: 15px;
    border: 2px solid #ffffff;
    background-color: rgba(255,255,255,0.3);
    /deep/ .el-tabs__header {
      padding: 10px 20px;
      .el-tabs__nav-wrap::after{
        background-color: transparent !important;
      }
    }
    .tabs-item{
      padding: 0 10px;
      /deep/ .el-table {
        background-color: transparent !important;
        thead{
          color: #3c3c3c !important;
        }
        th {
          background-color: transparent !important;
          border-bottom: none !important;
        }
        tr {
          background-color: transparent !important;
          td.el-table__cell{
            border-bottom: 1px solid rgba(59, 125, 248, 0.1) !important;
            background-color: transparent !important;
          }
        }
      }
      /deep/ .el-table::before{
        background-color: transparent !important;
      }
    }
  }
  .pagination{
    padding-top: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .pc-show{
      display: block;
    }
    .h5-show{
      display: none;
    }
  }
}
.width-show{
  /deep/ .el-dialog{
    border-radius: 15px;
    width: 25% !important;
  }
}
.from-dialog-box{
  width: 65%;
  margin: 0 auto;
  /deep/ .el-radio-group {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    .el-radio-button {
      width: 30%;
      display: block;
      margin-bottom: 15px;
      border-radius: 6px;
      border: 1px solid #eeeeee;
      .el-radio-button__inner {
        width: 100%;
        border: 0;
        padding: 11px 20px;
        border-radius: 6px;
      }
    }
  }
  .form-dialog-btn{
    text-align: center;
  }
}

@media screen and (max-width: 900px){
  .right-box {
    width: 100%;
    .user-item{
      flex-direction: column;
      justify-content: center;
      .user-name{
        margin: -50px auto 16px 0;
      }
      .user-info{
        width: 98%;
        justify-content: space-between;
        > div{
          display: flex;
          flex-direction: column;
          margin-left: 0;
          span{
            margin-bottom: 5px;
            font-size: 12px;
          }
        }
      }
    }
    .pagination{
      .pc-show{
        display: none;
      }
      .h5-show{
        display: block;
      }
    }
  }
  .width-show{
   /deep/ .el-dialog{
     width: 80% !important;
   }
  }
  .from-dialog-box {
    width: 100%;
  }
}
</style>
